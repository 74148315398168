import React from "react";
import { Modal } from "react-bootstrap";
import { FaRegCheckCircle } from "react-icons/fa";
import { FaRegCircleXmark } from "react-icons/fa6";

const SubmitModal = ({ submitModal, handleModalClose, successMessage, errorMessage, stage, handleClear }) => {
  const isSuccess = successMessage;
  const isError = errorMessage;

  console.log("isSuccess", isSuccess);

  return (
    <Modal show={submitModal} centered>
      <div className="tw-p-6 tw-text-center">
        {isSuccess === "show" ? null : (
          <>
        {isSuccess && (
          <div className="tw-mb-4 tw-flex tw-justify-center">
            <FaRegCheckCircle className="tw-text-green-500" size={48} />
          </div>
        )}
        {isError && (
          <div className="tw-mb-4 tw-flex tw-justify-center">
            <FaRegCircleXmark className="tw-text-red-500" size={48} />
          </div>
        )}
        <p className={` ${isError ? "" : "tw-font-semibold tw-text-xl"} tw-mb-4 tw-text-gray-800`}>{isSuccess ? isSuccess : isError}</p>
          </>
        )}
        { stage === "3" && (
          <>
        <p className="tw-text-lg tw-font-semibold tw-mb-4 tw-text-gray-700">
          WhatsApp can take up to 24 hours to review <br/> (approve / reject) a template
        </p>
        <p className="tw-text-base tw-text-gray-600 tw-mb-6">
          In some cases, after template submission, the approval / rejection
          comes within the first 1 minute itself. However, if the template's
          status shows pending (yellow) even after 1 minute, then, it implies
          that WhatsApp might have sent it for manual review, which typically
          takes upto 24 hours.
        </p>
          </>
        )}

        {stage === "2" && (
           <>
           <p className="tw-text-lg tw-font-semibold tw-mb-4 tw-text-gray-700">
           Template Approved and Sent for Further Approval
           </p>
           <p className="tw-text-base tw-text-gray-600 tw-mb-6">
           The template has been approved and submitted to the next level for review. You can track its progress in GOQii's Health Engage.
           </p>
             </>
        )}
        {stage === "1" && (
           <>
           <p className="tw-text-lg tw-font-semibold tw-mb-4 tw-text-gray-700">
           Template Submitted for Approval
           </p>
           <p className="tw-text-base tw-text-gray-600 tw-mb-6">
           Your template has been successfully submitted for approval. The Communication Manager and Care Coordinator will review it shortly.
           </p>
             </>
        )}
        <button
          onClick={handleModalClose}
          className="btn btn-primary add-new-program-btn"
        >
          OK
        </button>
      </div>
    </Modal>
  );
};

export default SubmitModal;