import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import './MedicationForms.css';
import { fetchUserProfile, saveMedicationMetaDoc, fetchFormDataMedication } from '../services/Service';
import axios from "axios";
import Select from 'react-select';
import MedicationsList from '../assessment/MedicationsList';
import { FaBullseye } from 'react-icons/fa6';


const MedicationFormsMetaDoc = ({ closeModal, selectedServiceRecord, callHomeBasedServicesHistory }) => {

    const qsParams = useParams();
    const userId = qsParams.memberId;

    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [disabledButton, setDisabledButton] = useState(true);
    const [cityList, setCityList] = useState([]);
    const [partnerList, setPartnerList] = useState([]);
    const [pharmacyList, setPharmacyList] = useState([]);

    const medicationsObj = {
        productId: '',
        quantity: '',
        comments: '',
    }

    const [formData, setFormData] = useState({
        userId: userId,
        selectedServiceRecord: selectedServiceRecord,
        membership: '',
        name: '',
        mobileNumber: '',
        partnerClinic: '',
        remarks: '',
        pharmacy: '',
        labProviderName: '',
        deliveryRefill: '',
        city: '',
        district: '',
        deductible: '',
        medications: [medicationsObj],
        cityId: ''
    });

    const [addMedicationsComponentCount, setAddMedicationsComponentCount] = useState(formData.medications.length);

    

    useEffect(() => {
        fetchUserData();
        dropdownData();
    }, [])


    const fetchUserData = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        setDisabledButton(true);
        await fetchUserProfile(userId,'')
            .then((response) => {
                if (response.data.code === 200) {
                    setFormData((prev) => ({ ...prev, membership: response.data.data.info.membershipNumber, name: response.data.data.info.memberName, mobileNumber: response.data.data.info.mobile }));
                    setDisabledButton(false);
                } else {
                    setError(response.data.data.message);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }


    const handleChange = async (e) => {
        const { name, value } = e.target;

        const updatedFormData = { ...formData, [name]: value };

        if (name === "pharmacy") {
            updatedFormData.labProviderName = e.target.selectedOptions[0].text;
        }
        setFormData(updatedFormData);

        setError('');
    };

    const validateForm = () => {
        const mandatoryFields = ['membership', 'name', 'mobileNumber', 'partnerClinic', 'pharmacy', 'deliveryRefill', 'city'];

        for (let field of mandatoryFields) {
            if ((field === 'city')) {
                if (formData[field] == "") {
                    setError('Enter all mandatory fields');
                    return false;
                }
            }else if (!formData[field].trim()) {
                setError('Enter all mandatory fields');
                return false;
            }

           
        }

        return true;
    };

    const handleChangeCity = (selectedOption) => {
        fetchCategoryList(selectedOption.value);
        setFormData({
            ...formData,
            ['city']: selectedOption.label,
            ['cityId']: selectedOption.value,
        });
    };

    const fetchCategoryList = async (cityName) => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchFormDataMedication(cityName,formData['membership'])
            .then((response) => {
                if (response.data.code === 200) {
                    setPharmacyList(response.data.data.pharmacy);
                } else {
                    setError(response.data.data.message);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const handleSubmit = async (event) => {
        setError('');
        event.preventDefault();
        if (validateForm()) {
            const request = axios.CancelToken.source();
            let ct = {
                cancelToken: request.token
            }
            setDisabledButton(true);
            await saveMedicationMetaDoc(formData)
                .then((response) => {
                    if (response.data.code === 200) {
                        
                        setMessage(response.data.data.message);
                        callHomeBasedServicesHistory();
                        setTimeout(() => {
                            closeModal();
                        }, 1000);
                    } else {
                        setMessage('');
                        setError(response.data.data.message);
                        setDisabledButton(false);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
        }
    }


    const dropdownData = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchFormDataMedication()
            .then((response) => {
                if (response.data.code === 200) {
                    setCityList(response.data.data.city);
                    setPharmacyList(response.data.data.pharmacy);
                    setPartnerList(response.data.data.partner);
                } else {
                    setError(response.data.data.message);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }


    const addComponent = () => {        
        setAddMedicationsComponentCount(addMedicationsComponentCount + 1);
        setFormData({
            ...formData,
            medications: [medicationsObj, ...formData.medications]
        });
    };


    const handleFieldChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...formData.medications];
        list[index][name] = value;
        if (name == "productId") {
            list[index]["code"] = ''
            list[index]["productName"] = ''
        }
        // if (name === "categoryId") {
        //     const { options, selectedIndex } = e.target;
        //     list[index]["categoryName"] = options[selectedIndex].text;
        // }
        setFormData({ ...formData, medications: list });
    }


    const removeComponent = (index) => {
        setFormData((prevFormObject) => {
            const newArr = [...prevFormObject.medications];
            newArr.splice(index, 1);
            return { ...prevFormObject, medications: newArr };
        });

        setAddMedicationsComponentCount(prevCount => prevCount - 1);
    };

    const handleSuggestionClick = (suggestion, index) => {
        const list = [...formData.medications];
        list[index]['productId'] = suggestion.productId;
        list[index]['productName'] = suggestion.productName;
        setFormData({ ...formData, medications: list });
    };

    return (
        <>

            <div className='col-md-12'>
                <div className='card card-task-appoint'>
                    <div className='card-body'>
                        <div className='card-text'></div>
                        <form id="medicationForm" onSubmit={handleSubmit}>

                            <div className='row'>

                                <div className='col-md-6 mb-3'>
                                    <div className="row">
                                        <div className='col-md-6 mb-1'>
                                            <label className="form-label">Membership</label>
                                            <h4 name="membership">{formData['membership']}</h4>
                                        </div>

                                        <div className='col-md-6 mb-1'>
                                            <label className="form-label">Name</label>
                                            <h4 name="name">{formData['name']}</h4>
                                        </div>

                                        <div className='col-md-6 mb-3 mt-4'>
                                            <label className="form-label">Mobile Number
                                                <span className='text-danger'>*</span></label>
                                            <input type="text" name="mobileNumber" className='form-control control-input-ui' value={formData['mobileNumber']} onChange={handleChange} />
                                        </div>

                                        <div className='col-md-6 mb-3 mt-4'>
                                            <label className="form-label"> Partner Clinic
                                                <span className='text-danger'>*</span></label>
                                            <select className="form-select select-input-ui" name="partnerClinic" id="partnerClinic" value={formData['partnerClinic']} onChange={handleChange} >
                                                <option value="">-- Select Partner Clinic --</option>
                                                {partnerList.map(option => (
                                                    <option key={option.id} value={option.id}>{option.partnerName}</option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className='col-md-12 mb-3'>
                                            <label className="form-label"> Deductible</label>
                                            <input type="text" name="deductible" className='form-control control-input-ui' value={formData['deductible']} onChange={handleChange} />
                                        </div>
                                        
                                    </div>
                                </div>

                                <div className='col-md mb-3 '>
                                    <div className="row">

                                        <div className='col-md-6 mb-3'>
                                            <label className="form-label">District</label>
                                            <input type="text" name="district" className='form-control control-input-ui' value={formData['district']} onChange={handleChange} />
                                        </div>

                                        <div className='col-md-6 mb-3'>
                                            <label className="form-label"> Delivery/Refill
                                                <span className='text-danger'>*</span></label>
                                            <select className="form-select select-input-ui" name="deliveryRefill" id="deliveryRefill" value={formData['deliveryRefill']} onChange={handleChange} >
                                                <option value="">-- Select Delivery/Refill --</option>
                                                <option value="Delivery">Delivery</option>
                                                <option value="Refill">Refill</option>
                                            </select>
                                        </div>

                                        <div className='col-md-6 mb-3'>
                                            <label className="form-label">City
                                                <span className='text-danger'>*</span>
                                            </label>

                                            <Select
                                                value={cityList.find(option => option.label === formData.city) || null}
                                                onChange={handleChangeCity}
                                                options={cityList}
                                                isSearchable={true}
                                                placeholder="Select City"
                                                className="form-control select-input-ui select-input-ui-select"
                                            />
                                        </div>

                                        <div className='col-md-6 mb-3'>
                                            <label className="form-label">Pharmacy
                                                <span className='text-danger'>*</span></label>
                                            <select className="form-select select-input-ui" name="pharmacy" id="pharmacy" value={formData['pharmacy']} onChange={handleChange} >
                                                <option value="">-- Select Pharmacy --</option>
                                                {pharmacyList?.map(option => (
                                                    <option key={option.id} value={option.id}>{option.pharmacyName}</option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className='col-md-12 mb-3'>
                                            <label className="form-label">Remarks</label>
                                            <input type="text" name="remarks" className='form-control control-input-ui' value={formData['remarks']} onChange={handleChange} />
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-12 mb-3'>
                                            <label className="form-label"> Medications<span className='text-danger'>*</span></label>
                                            <button type="button" className="btn btn-primary addNewRow-btn ms-4" onClick={() => addComponent()}><i className="bi bi-plus"></i> Add </button>
                                            <div className='col-12'>
                                                <div className="table-responsive">
                                                    <table className="table table-borderless mb-1">
                                                        <thead>
                                                            <tr>
                                                                <td className='ps-1 pb-1'>Product <span className='text-danger'>*</span></td>
                                                                <td className='ps-1 pb-1'>Quantity <span className='text-danger'>*</span></td>
                                                                <td className='ps-1 pb-1'>Comments</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {[...Array(addMedicationsComponentCount)].map((_, index) => (
                                                                <MedicationsList c={index} key={index} handleFieldChange={handleFieldChange} data={formData.medications[index]} removeComponent={removeComponent} listType="medications" handleSuggestionClick={handleSuggestionClick} />
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                <div className='col-md-12 text-center'>
                                    <div className={`${error != "" ? "errorDiv" : "hide"}`}>{error}</div>
                                    <div className={`${message != "" ? "messageDiv" : "hide"}`}>{message}</div>
                                    <Button variant="primary" type="submit" className="btn btn-primary save-btn me-3" disabled={disabledButton}>Save Medication Request</Button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>



        </>
    )
}

export default MedicationFormsMetaDoc;