import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  viewTemplateHistory,
} from "../../../services/mtp/Service.js";
import { Link } from "react-router-dom";
import LogModal from "../../WhatsAppTemplates/component/LogModal.jsx";
import BackendDrivenDataTable from "../../cn/components/common/BackendDrivenDataTable.js";

const StatusBadge = ({ status }) => {
    
    // Convert status to lowercase once for comparison
    const statusLower = status.toLowerCase();

    console.log(statusLower);
    
    return (
      <span
        style={
          statusLower === "approved"
            ? { color: "#4CAF50", fontSize: "12px", fontWeight: "500", textTransform: "capitalize" }
            : statusLower === "approval pending"
            ? { color: "#FFC107", fontSize: "12px", fontWeight: "500", textTransform: "capitalize" }
            :  statusLower === "pending review"
            ? { color: "#FFC107", fontSize: "12px", fontWeight: "500", textTransform: "capitalize" }
            : { color: "#4CAF50", fontSize: "12px", fontWeight: "500", textTransform: "capitalize" } 
        }
      >
        {status}
      </span>
    );
  };

const CareCoordinatePendingTemplate = ({ templates, getTemplates }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [selectedLanguage, setSelectedLanguage] = useState("All");
  // const [selectedTags, setSelectedTags] = useState([]);
  const [viewHistoryData, setViewHistoryData] = useState([]);
  const [viewHistoryApprovedData, setViewHistoryApprovedData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // New state for BackendDrivenDataTable
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [displayData, setDisplayData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  const columns = [
    {
      name: "Template Name",
      selector: row => row.templateName,
      sortable: true,
    },
    {
      name: "Status",
      selector: row => <StatusBadge status={row.status} />,
      sortable: true,
    },
    {
      name: "Category",
      selector: row => row.category,
      sortable: true
    },
    {
      name: "Date Uploaded",
      selector: row => row.createdTime,
      sortable: true,
    },
    {
      name: "Language",
      selector: row => row.language,
      sortable: true
    },
    {
      name: "Created By",
      selector: row => row.createdBy,
      sortable: true
    },
    {
      name: "Approver Comment",
      selector: row => row.comments,
      sortable: false,
    },
    {
      name: "Action",
      cell: (row) =>
          <div className="d-flex align-items-center">
            <OverlayTrigger placement="top" overlay={<Tooltip>View</Tooltip>}>
              <Link to={`/care-coordinate-review-template/${row.templateId}`}>
                <span className="tw-px-2">
                  <i className="bi bi-eye tw-text-black hover:tw-text-[#215a87]"></i>
                </span>
              </Link>
            </OverlayTrigger>
            <span className="">|</span>
              <OverlayTrigger placement="top" overlay={<Tooltip>Template History</Tooltip>}>
                <span onClick={() => handleViewHistory(row.templateId)} className="tw-px-2 tw-cursor-pointer">
                  <i className="bi bi-info-circle tw-text-black hover:tw-text-[#215a87]"></i>
                </span>
              </OverlayTrigger>
          </div>
    },
    
  ];

  const handleViewHistory = async (templateId) => {
        try {
              let resp = await viewTemplateHistory(templateId);
              setError("");
              setLoading(true);
              if(resp.data.code === 200){
                  setViewHistoryData(resp.data.data);
                  setViewHistoryApprovedData(resp.data.approvedData);
                  setIsOpen(true);
              }else{
                setError(resp.data.message);
              }
    
            } catch (err) {
              setError('Error history template. Please try again.');
            } finally {
              setLoading(false);
            }
        };

  // Filter and update data when filters or pagination changes
  useEffect(() => {
    // Apply filters
    const filteredData = templates.templateData?.filter((item) => {
      const matchesSearchQuery =
        item.templateName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.createdBy.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesCategory =
        selectedCategory === "All" ||
        item.category.toLowerCase() === selectedCategory.toLowerCase();
      const matchesStatus =
        selectedStatus === "All" ||
        item.status.toLowerCase() === selectedStatus.toLowerCase();
      const matchesLanguage =
        selectedLanguage === "All" ||
        item.language.toLowerCase() === selectedLanguage.toLowerCase();
      return (
        matchesSearchQuery && matchesCategory && matchesStatus && matchesLanguage
      );
    }) || [];

    // Set total records for pagination
    setTotalRecords(filteredData.length);

    // Sort data if sort column is specified
    let sortedData = [...filteredData];
    if (sortColumn) {
      sortedData.sort((a, b) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];
        if (sortDirection === 'asc') {
          return aValue > bValue ? 1 : -1;
        } else {
          return aValue < bValue ? 1 : -1;
        }
      });
    }

    // Calculate pagination
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    
    // Set displayed data
    setDisplayData(sortedData.slice(startIndex, endIndex));
  }, [
    templates.templateData,
    searchQuery,
    selectedCategory,
    selectedStatus,
    selectedLanguage,
    currentPage,
    rowsPerPage,
    sortColumn,
    sortDirection
  ]);

  const handleClear = () => {
    setSearchQuery("");
    setSelectedCategory("All");
    setSelectedStatus("All");
    setSelectedLanguage("All");
    setCurrentPage(1); // Reset to first page when filters are cleared
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-3 align-items-center">
          <div className="col-md d-flex px-1">
            <div className="form-group" style={{ width: "100%" }}>
              <input
                type="text"
                placeholder="Search By Template Name or Creator"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  setCurrentPage(1); // Reset to first page on search
                }}
                className="form-control input-field-ui ps-5"
              />
              <img
                src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png"
                className="input-icon-seacrh"
                alt=""
              />
            </div>
          </div>
          <div className="col-md-2 px-1">
            <select
              value={selectedStatus}
              onChange={(e) => {
                setSelectedStatus(e.target.value);
                setCurrentPage(1);
              }}
              className="form-select input-field-ui"
            >
              <option value="All">--Select Status--</option>
              <option value="Pending Review">Pending Review</option>
                <option value="Reviewed">Reviewed</option>
            </select>
          </div>
          <div className="col-md-2 px-1">
            <select
              value={selectedCategory}
              onChange={(e) => {
                setSelectedCategory(e.target.value);
                setCurrentPage(1);
              }}
              className="form-select input-field-ui"
            >
              <option value="All">--Select Category--</option>
              <option value="marketing">Marketing</option>
              <option value="utility">Utility</option>
              <option value="authentication">Authentication</option>
            </select>
          </div>
          <div className="col-md-2 px-1">
            <select
              value={selectedLanguage}
              onChange={(e) => {
                setSelectedLanguage(e.target.value);
                setCurrentPage(1);
              }}
              className="form-select input-field-ui"
            >
              <option value="All">--Select Language--</option>
              <option value="english">English</option>
              <option value="arabic">Arabic</option>
            </select>
          </div>

          <div className="col-md-auto px-1">
            <button
              onClick={handleClear}
              className="btn btn-primary clear-search-btn"
            >
              Clear
            </button>
          </div>
          <div className="col-md-auto pe-1">
            <h6 className="last-sync">Latest sync on</h6>
            <h6 className="last-sync">
              <span>{templates.lastSync || "Fetching..."}</span>
            </h6>
          </div>
          <div className="col-md-auto px-1">
            <button
              className="btn btn-outline-dark"
              onClick={(e) => getTemplates("active3", 1)}
            >
              <i className="bi bi-arrow-clockwise"></i>
            </button>
          </div>
        </div>
      </div>

      <BackendDrivenDataTable
        columns={columns}
        data={displayData}
        page={currentPage}
        setPage={setCurrentPage}
        perPage={rowsPerPage}
        setPerPage={setRowsPerPage}
        totalRows={totalRecords}
        sortColumn={sortColumn}
        setSortColumn={setSortColumn}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
      />

      <LogModal
        isOpen={isOpen}
        viewHistoryData={viewHistoryData || []}
        onClose={() => setIsOpen(false)}
        viewHistoryApprovedData={viewHistoryApprovedData || []}
      />
    </>
  );
};

export default CareCoordinatePendingTemplate;