import React from 'react';
import { BsDownload, BsX } from 'react-icons/bs';

const MediaModalChat = ({ isOpen, onClose, url, mediaType, onDownload }) => {
  if (!isOpen) return null;

  return (
    <div className="tw-fixed tw-inset-0 tw-z-[99999999] tw-flex tw-items-center tw-justify-center">
      {/* Backdrop */}
      <div 
        className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50"
        onClick={onClose}
      />
      
      {/* Modal Content */}
      <div className="tw-relative tw-bg-white tw-rounded-lg tw-max-w-5xl tw-max-h-[80vh]  tw-overflow-y-auto tw-w-full tw-mx-4 tw-z-50">
        {/* Header */}
        <div className="tw-flex tw-justify-between tw-items-center tw-p-4 tw-border-b tw-sticky tw-top-0 tw-bg-white">
          <h2 className="tw-text-2xl tw-font-semibold tw-text-gray-600">Media Preview</h2>
          
          <div className="tw-flex tw-items-center tw-gap-4">
                      <span
                        onClick={onDownload}
                        className={`tw-inline-flex tw-items-center tw-cursor-pointer tw-justify-center tw-h-[38px] tw-bg-[#03335b] hover:tw-bg-[#1279BE] tw-transition-colors tw-duration-200 tw-text-white tw-rounded-xl tw-px-3 ${mediaType === 'document' ? 'tw-hidden' : ''}`}
                      >
                        <BsDownload size={15} />
                       
                      </span>
                      
                      <span
                        onClick={onClose}
                        className="tw-text-gray-500 tw-text-5xl tw-cursor-pointer hover:tw-text-gray-700 tw-transition-colors"
                      >
                        <BsX />
                      </span>
                    </div>
        </div>

        {/* Content */}
        <div className="tw-p-4">
          <div className="tw-flex tw-justify-center">
            {(mediaType === 'image' || mediaType === 'sticker') && (
              <img
                src={url}
                alt="Preview"
                className="tw-h-[60vh] tw-object-contain tw-rounded"
              />
            )}
            {mediaType === 'video' && (
              <video
                controls
                className="tw-h-[60vh] tw-object-contain tw-w-full"
              >
                <source src={url} />
                Your browser does not support the video tag.
              </video>
            )}
            {mediaType === 'document' && (
              <iframe
                src={url}
                className="tw-w-full tw-h-[60vh] tw-object-contain"
                title="Document Preview"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaModalChat;