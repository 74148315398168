import React, { useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highcharts3d from 'highcharts/highcharts-3d';
import exporting from 'highcharts/modules/exporting';
import { capitalizeFirstLetter } from '../../../utils/Utils';


// Initialize exporting module
exporting(Highcharts);
highcharts3d(Highcharts);

const CallStatusDistribution = ({data, title}) => {
  
  const headers = Object.keys(data);
  const [colorsArray] = useState(["#145EC5", "#3780B8", "#37A6E4", "#93C9FE", "#FF6B6B", "#4ECDC4", "#FFD93D", "#95A5A6", "#BDC3C7", "#7F8C8D"]);
  
  let defaultData = [];

  headers.forEach((name, ind)=> {
     defaultData.push({ 
      name: name === "" ? "Other": capitalizeFirstLetter(name),
      y: data[name], 
      color: ind >= colorsArray.length ? Math.floor(Math.random() * (colorsArray.length + 1)): colorsArray[ind]}); 
  });
    
  defaultData = defaultData.filter(item => item.y > 0);

  const pieData = defaultData;

  const pieOptions = {
    chart: {
      type: 'pie',
      backgroundColor: 'transparent',
      height: 260,
      options3d: {
          enabled: true,
          alpha: 70,
          beta: 0,
          depth: 50,
          viewDistance: 0
      },
    },
    title: "    ",
    subtitle: null,
    tooltip: {
       pointFormat: '{point.name}: <b>{point.y} ({point.percentage:.1f}%)</b>'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        depth: 30,
        size: 190,
        dataLabels: {
          enabled: true,
          format: '{point.percentage:.1f}%',
          distance: 5,
          alignTo: 'toPlotEdges'
        },
        showInLegend: true
      }
    },
    series: [{
      name: title,
      data: pieData
    }],
    credits: {
      enabled: false
    },
    exporting: {
          enabled: true,
          buttons: {
            contextButton: {
              enabled: false
            }
          }
        },
    legend: {
      enabled: true,
      labelFormatter: function() {
        return this.y > 0 ? this.name : null;
      }
    }
  };

  return (
    <div>
      {pieData?.length > 0 && pieData.some(item => item.y > 0) ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={pieOptions}
        />
      ) : (
        <div className="emty-pie">
          <h5 className="emty-title-graph">There are no data to display</h5>
        </div>
      )}
    </div>
  );
};

export default CallStatusDistribution;
