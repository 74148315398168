import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import './HomeBasedVaccine.css';
import { fetchUserProfile, saveHomeBaseMetaDoc, fetchFormDataBase } from '../services/Service';
import axios from "axios";
import Select from 'react-select';

const HomeBasedVaccineMetaDoc = ({ closeModal, selectedServiceRecord, callHomeBasedServicesHistory, membershipNoMember }) => {

    const qsParams = useParams();
    const userId = qsParams.memberId;

    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [disabledButton, setDisabledButton] = useState(true);
    const [cityList, setCityList] = useState([]);
    const [productList, setProductList] = useState([]);

    const [formData, setFormData] = useState({
        userId: userId,
        selectedServiceRecord: selectedServiceRecord,
        membership: '',
        name: '',
        mobileNumber: '',
        deductible: '',
        city: '',
        district: '',
        remarks: '',
        requestedHomeHealth: '',
        nationality: '',
        vaccine: '',
        partnerClinic: 'Direct Request',
        cityId: ''
    });

    useEffect(() => {
        fetchUserData();
        dropdownData();
    }, [])


    const fetchUserData = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchUserProfile(userId,'')
            .then((response) => {
                if (response.data.code === 200) {
                    setFormData((prev) => ({ ...prev, membership: response.data.data.info.membershipNumber, name: response.data.data.info.memberName, mobileNumber: response.data.data.info.mobile, nationality: response.data.data.info.nationality }));
                    setDisabledButton(false);
                } else {
                    setError(response.data.data.message);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }


    const handleChange = async (e) => {
        const { name, value } = e.target;
        // if (name === "deductible") {
        //     if (!/^\d*\.?\d*$/.test(value)) {
        //         return false;
        //     }
        // }
        setFormData({
            ...formData,
            [name]: value,
        });

        setError('');
    };

    const validateForm = () => {
        const mandatoryFields = ['membership', 'name', 'mobileNumber', 'vaccine', 'partnerClinic', 'deductible', 'city', 'district','requestedHomeHealth'];

        for (let field of mandatoryFields) {
            if ((field === 'city')) {
                if (formData[field] == "") {
                    setError('Enter all mandatory fields');
                    return false;
                }
            } else if (!formData[field].trim()) {
                setError('Enter all mandatory fields');
                return false;
            }
        }

        return true;
    };

    const dropdownData = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchFormDataBase(membershipNoMember)
            .then((response) => {
                if (response.data.code === 200) {
                    setCityList(response.data.data.city);
                    setProductList(response.data.data.products);
                } else {
                    setError(response.data.data.message);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }


    const handleSubmit = async (event) => {
        setError('');
        event.preventDefault();
        if (validateForm()) {
            const request = axios.CancelToken.source();
            let ct = {
                cancelToken: request.token
            }
            setDisabledButton(true);
            await saveHomeBaseMetaDoc(formData)
                .then((response) => {
                    if (response.data.code === 200) {
                        setMessage(response.data.data.message);
                        callHomeBasedServicesHistory();
                        setTimeout(() => {
                            closeModal();
                        }, 1000);
                    } else {
                        setMessage('');
                        setError(response.data.data.message);
                        setDisabledButton(false);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
        }
    }

    const handleChangeCity = (selectedOption) => {
        setFormData({
            ...formData,
            ['city']: selectedOption.label,
            ['cityId']: selectedOption.value,
        });
    };

    return (
        <>
            <div className='col-md-12'>
                <div className='card card-task-appoint'>
                    <div className='card-body'>
                        <div className='card-text'></div>
                        <form id="medicationForm" onSubmit={handleSubmit}>

                            <div className='row align-items-center'>

                                <div className='col-md-6 mb-3 row'>
                                    <div className='col-md-6 mb-3'>
                                        <label className="form-label">Child Membership</label>
                                        <h4 name="membership">{formData['membership']}</h4>
                                    </div>

                                    <div className='col-md-6 mb-3'>
                                        <label className="form-label">Child Name</label>
                                        <h4 name="name">{formData['name']}</h4>
                                    </div>

                                    <div className='col-md-6 mb-4'>
                                        <label className="form-label">Mobile Number</label>
                                        {/* <h4 name="mobileNumber">{formData['mobileNumber']}</h4> */}
                                        <input type="text" name="mobileNumber" className='form-control control-input-ui' value={formData['mobileNumber']} onChange={handleChange} />
                                    </div>

                                    <div className='col-md-6 mb-4'>
                                        <label className="form-label">City
                                            <span className='text-danger'>*</span>
                                        </label>
                                        {/* <h4 name="city">{formData['city']}</h4> */}
                                        {/* <select className="form-select select-input-ui" name="city" id="city" value={formData['city']} onChange={handleChange} >
                                <option value="">-- Select City --</option>
                                {cityList.map(option => (
                                    <option key={option.id} value={option.cityName}>{option.cityName}</option>
                                ))}
                            </select> */}

                                        <Select
                                            value={cityList.find(option => option.label === formData.city) || null}
                                            onChange={handleChangeCity}
                                            options={cityList}
                                            isSearchable={true}
                                            placeholder="Select City"
                                            className="form-control select-input-ui select-input-ui-select"
                                        />
                                    </div>

                                    <div className='col-md-6 mb-2'>
                                        <label className="form-label">District
                                            <span className='text-danger'>*</span>
                                        </label>
                                        <input type="text" name="district" className='form-control control-input-ui' value={formData['district']} onChange={handleChange} />
                                    </div>

                                    <div className='col-md-6 mb-2'>
                                        <label className="form-label">Nationality<span className='text-danger'>*</span>
                                        </label>
                                        {/* <input type="text" name="nationality" className='form-control control-input-ui' value={formData['nationality']} onChange={handleChange}/> */}
                                        <select className="form-select select-input-ui" name="nationality" id="nationality" value={formData['nationality']} onChange={handleChange} >
                                            <option value="">-- Select Nationality --</option>
                                            <option value="Saudi Arabian">Saudi Arabian</option>
                                            <option value="Non Saudi Arabian">Non Saudi Arabian</option>
                                        </select>
                                    </div>
                                </div>

                                <div className='col-md mb-3 row'>
                                    <div className='col-md-6 mb-3'>
                                        <label className="form-label">Vaccine
                                            <span className='text-danger'>*</span>
                                        </label>
                                        <input type="text" name="vaccine" className='form-control control-input-ui' value={formData['vaccine']} onChange={handleChange} />
                                    </div>

                                    <div className='col-md-6 mb-3'>
                                        <label className="form-label">Deductible
                                            <span className='text-danger'>*</span>
                                        </label>
                                        <input type="text" name="deductible" className='form-control control-input-ui' value={formData['deductible']} onChange={handleChange} />
                                    </div>

                                    <div className='col-md-6 mb-3'>
                                        <label className="form-label">Partner Clinic
                                            <span className='text-danger'>*</span>
                                        </label>
                                        <input type="text" name="partnerClinic" className='form-control control-input-ui' disabled={true} value={formData['partnerClinic']} onChange={handleChange} />
                                    </div>

                                    <div className='col-md-6 mb-3'>
                                        <label className="form-label">Home Health Care service<span className='text-danger'>*</span>
                                        </label>
                                        {/* <input type="text" name="requestedHomeHealth" className='form-control control-input-ui' value={formData['requestedHomeHealth']} onChange={handleChange} /> */}
                                        <select className="form-select select-input-ui" name="requestedHomeHealth" id="requestedHomeHealth" value={formData['requestedHomeHealth']} onChange={handleChange} >
                                            <option value="">Select Product</option>
                                            {productList?.map(option => (
                                                <option key={option.id} value={option.id}>{option.label}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className='col-md-12 mb-2 mt-1'>
                                        <label className="form-label">Remarks</label>
                                        <input type="text" name="remarks" className='form-control control-input-ui' value={formData['remarks']} onChange={handleChange} />
                                    </div>
                                </div>



                                <div className='col-md-12 text-center mt-2'>
                                    <div className={`${error != "" ? "errorDiv" : "hide"}`}>{error}</div>
                                    <div className={`${message != "" ? "messageDiv" : "hide"}`}>{message}</div>
                                    <Button variant="primary" type="submit" className="btn btn-primary save-btn me-3" disabled={disabledButton}>Save Home Based Vaccine
                                    </Button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>

        </>
    )
}

export default HomeBasedVaccineMetaDoc;