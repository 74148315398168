import React,{useState,useEffect} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';
import { formatDate } from '../../utils/CommonLibrary';
import { agent_target_achievement_graph } from '../../../services/met/Service';

// Initialize exporting module
exporting(Highcharts);

const TargetAchievementBar = ({startDate,endDate,agentId,requestFrom,parentCallback}) => {
  // Define your data

  const [loadingCount, setLoadingCount] = useState(0);
  const [graphData, setGraphData] = useState([]);
  const [targetData, setTargetData] = useState([]);
  const [achievedData, setAchievedData] = useState([]);
  const [weekBooked, setWeekBooked] = useState([]);
  
  useEffect(() => {
    agentTargetAchievedGraph();    
}, [agentId,startDate,endDate]);

  const agentTargetAchievedGraph = async () => {
    try {
        let sdate = formatDate(startDate);
        let edate = formatDate(endDate);
        let response = await agent_target_achievement_graph(sdate,edate,agentId,requestFrom);
        if(response.data.code === 200){
            setGraphData(response.data.data);
            setLoadingCount(response.data.data.length)
        }
    } catch (error) {
        console.log(error);
    }
}

useEffect(() => {
  if (graphData.length > 0) {
    const dates = graphData.map((item) => item.date);
    const target = graphData.map((item) => item.enrollmentTargetBatch);
    const achieved = graphData.map((item) => item.achievedTarget);
    setWeekBooked(dates);
    setTargetData(target);
    setAchievedData(achieved);
    parentCallback(achieved);
  }
}, [graphData]);

  const yAxisJson = {
    title: {
      text: null
    }
  };

  const tooltipJson = {
    shared: true,
    valueSuffix: ' '
  };

  const seriesData = [{
    name: 'Target value',
    data: targetData,
    color: '#2266A6'
  }, {
    name: 'Achieved',
    data: achievedData,
    color: '#39BCC7'
  }];

  const optionsMCI6 = {
    chart: {
      type: 'line',
      height: 200,
      spacing: [20, 0, 0, 0],
      options3d: {
        enabled: true,
        alpha: 0,
        beta: 10,
        depth: 50,
        viewDistance: 25
      }
    },
    title: {
      text: null,
      align: 'left',
      style: {
        fontSize: '20px',
        fontWeight: '600',
        color: '#333',
        fontFamily: "IBM Plex Sans, sans-serif"
      }
    },
    xAxis: {
      categories: weekBooked,
      gridLineWidth: 0
    },
    yAxis: yAxisJson,
    tooltip: tooltipJson,
    plotOptions: {
      column: {
        borderRadius: 5,
        pointPadding: 0.2,
        groupPadding: 0.1,
        stacking: null, // Use null for side-by-side columns
        dataLabels: {
          enabled: true,
          style: {
            textOutline: 'none'
          }
        }
      }
    },
    series: seriesData,
    credits: {
      enabled: false
    },
    exporting: {
          enabled: true,
          buttons: {
            contextButton: {
              enabled: false
            }
          }
        },
    legend: {
      enabled: true
    }
  };

  return (
    <>
    {loadingCount > 0 ? (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={optionsMCI6}
      />
    </div>
    ) : (

      <div className="emty-line">
                                    <h5 className="emty-title-graph">There are no data to display</h5>
                                </div>
    )} 
    </>
  );
};

export default TargetAchievementBar;
