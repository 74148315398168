import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';
import highcharts3d from 'highcharts/highcharts-3d';

highcharts3d(Highcharts);

// Initialize exporting module
exporting(Highcharts);

const ObservationsPie = () => {
  // Updated static data for pie chart
  const categories = ['Not Started', 'In Progress', 'Completed'];
  const data = [5, 15, 24];
  const colors = ['#007BFF', '#8295FA', '#2F1679'];

  const options2 = {
    chart: {
        type: 'pie',
        height: 200,
        spacing: [0, 0, 0, 0],
        options3d: {
          enabled: true,
          alpha: 70,
          beta: 0
        },
      },
    title: {
      text: null,
      align: 'left',
      style: {
        fontSize: '20px',
        fontWeight: '600',
        color: '#333',
        fontFamily: "IBM Plex Sans, sans-serif"
      }
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b> ({point.y} observations)'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        depth: 35,
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          style: {
            textOutline: 'none'
          }
        }
      }
    },
    series: [{
      name: 'Observations by Status',
      colorByPoint: true,
      data: categories.map((name, index) => ({
        name: name,
        y: data[index],
        color: colors[index]
      }))
    }],
    credits: {
      enabled: false
    },
    exporting: {
          enabled: true,
          buttons: {
            contextButton: {
              enabled: false
            }
          }
        },
    legend: {
      enabled: true
    }
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options2}
      />
    </div>
  );
};

export default ObservationsPie;
