import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';

exporting(Highcharts);

const PathwayAdherenceRateLineChart = () => {
  // Example data for demonstration
  const adherenceData = [
    { month: 'Jan', adherenceRate: 80 },
    { month: 'Feb', adherenceRate: 85 },
    { month: 'Mar', adherenceRate: 75 },
    { month: 'Apr', adherenceRate: 90 },
    { month: 'May', adherenceRate: 88 },
    { month: 'Jun', adherenceRate: 82 }
  ];

  const options5 = {
    chart: {
      type: 'line',
      height: 220,
      spacing: [0, 0, 0, 0],
      options3d: {
        enabled: true,
        alpha: 0,
        beta: 15,
        depth: 50,
        viewDistance: 25 // Adjusted viewDistance for better 3D effect
      }
    },
    title: {
      text: null,
      align: 'left',
      style: {
        fontSize: '20px',
        fontWeight: '600',
        color: '#333',
        fontFamily: "IBM Plex Sans, sans-serif"
      }
    },
    xAxis: {
      categories: adherenceData.map(data => data.month),
      gridLineWidth: 0
    },
    yAxis: {
      title: {
        text: null
      }
    },
    tooltip: {
      formatter: function () {
        return '<b>' + this.x + '</b><br/>' +
          'Adherence Rate: ' + this.y + '%';
      }
    },
    series: [{
      name: 'Adherence Rate',
      data: adherenceData.map(data => data.adherenceRate),
      color: '#4caf50'
    }],
    credits: {
      enabled: false
    },
    exporting: {
          enabled: true,
          buttons: {
            contextButton: {
              enabled: false
            }
          }
        },
    legend: {
      enabled: true
    }
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options5}
      />
    </div>
  );
};

export default PathwayAdherenceRateLineChart;
