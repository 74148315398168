import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highcharts3d from 'highcharts/highcharts-3d';
import exporting from 'highcharts/modules/exporting';

// Initialize exporting module
exporting(Highcharts);
highcharts3d(Highcharts);

const TypeDistribution = (props) => {
  const { data } = props;

  const pieData = [
    { name: 'New Requirement', y: data?.newRequirement || 0, color: '#1F5B97' },
    { name: 'Issue', y: data?.issue || 0, color: '#6981FE' },
    { name: 'Suggestion', y: data?.suggestion || 0, color: '#04A0AF' },
    { name: 'Enhancement Request', y: data?.enhancement || 0, color: '#008FFB' }
  ].filter(item => item.y > 0);

  const pieOptions = {
    chart: {
      type: 'pie',
      backgroundColor: 'transparent',
      height: 260,
      options3d: {
        enabled: true,
        alpha: 70,
        beta: 0,
        depth: 50,
        viewDistance: 0
      },
    },
    title: {
      text: null
    },
    tooltip: {
      pointFormat: '{point.name}: <b>{point.y} ({point.percentage:.1f}%)</b>'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        depth: 30,
        size: 190,
        dataLabels: {
          enabled: true,
          format: '{point.percentage:.1f}%',
          distance: 5,
          alignTo: 'toPlotEdges'
        },
        showInLegend: true
      }
    },
    series: [{
      name: 'Observation Type',
      data: pieData
    }],
    credits: {
      enabled: false
    },
    exporting: {
          enabled: true,
          buttons: {
            contextButton: {
              enabled: false
            }
          }
        },
    legend: {
      enabled: true, 
      labelFormatter: function() {
        return this.y > 0 ? this.name : null;
      }
    }
  };

  return (
    <div>
      {pieData?.length > 0 && pieData.some(item => item.y > 0) ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={pieOptions}
        />
      ) : (
        <div className="emty-pie">
          <h5 className="emty-title-graph">There are no data to display</h5>
        </div>
      )}
    </div>
  );
};

export default TypeDistribution;
