import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highcharts3d from 'highcharts/highcharts-3d';
import exporting from 'highcharts/modules/exporting';

// Initialize exporting module
exporting(Highcharts);
highcharts3d(Highcharts);

const CNAppointmentsBookedBy = (props) => {
    // Pie chart data

    const pieData = props.series;

    const pieOptions = {
        chart: {
            type: 'pie',
            backgroundColor: 'transparent',
            height: 260,
            options3d: {
                enabled: true,
                alpha: 70,
                beta: 0,
                depth: 50,
                viewDistance: 0
            },
        },
        title: "    ",
        subtitle: null,
        tooltip: {
            pointFormat: '{point.name}: <b>{point.y} ({point.percentage:.1f}%)</b>'
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                depth: 30,
                size: 190,
                dataLabels: {
                    enabled: true, // Enable data labels
                    format: '{point.percentage:.1f}%',
                    distance: 5, // Adjust label position
                    alignTo: 'toPlotEdges' // Center the label inside the pie slice
                },
                showInLegend: true
            }
        },
        series: [{
            name: 'Booked by',
            data: pieData
        }],
        credits: {
            enabled: false
        },
        exporting: {
              enabled: true,
              buttons: {
                contextButton: {
                  enabled: false
                }
              }
            },
        legend: {
            enabled: true
        }
    };

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={pieOptions}
            />
        </div>
    );
};

export default CNAppointmentsBookedBy;
