import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';
import highcharts3d from 'highcharts/highcharts-3d';

// Initialize exporting module
exporting(Highcharts);
highcharts3d(Highcharts);

const SortedWhatsAppAnalyticsBar = (props) => { 
  const { dataMonth } = props;
  const { columnName: careNavigators, dataValue: membersManaged, color: colorCode, displayName: axisName, subvalue: valueSuffix } = dataMonth;

  const convertTimeToSeconds = (timeStr) => {
    if (typeof timeStr !== "string") {
      return 0;
    }
    // Updated regex to handle days: "Yd Xhr YYmin ZZsec" or "Xhr YYmin ZZsec"
    const timeRegex = /(?:(\d+)d\s*)?(\d+)hr\s+(\d+)min\s+(\d+)sec/;
    const match = timeStr.match(timeRegex);
    if (match) {
      const days = parseInt(match[1] || 0, 10);
      const hours = parseInt(match[2] || 0, 10);
      const minutes = parseInt(match[3] || 0, 10);
      const seconds = parseInt(match[4] || 0, 10);
      return (days * 24 * 3600) + (hours * 3600) + (minutes * 60) + seconds;
    }
    return 0;
  };

  // Create an array of objects with both the original time and converted seconds
  const dataWithSeconds = membersManaged.map((timeStr, index) => {
    const seconds = convertTimeToSeconds(timeStr);
    return {
      timeStr: timeStr || "0hr 0min 0sec",
      seconds: seconds,
      navigator: careNavigators[index]
    };
  });

  // Sort the data by seconds in descending order
  const sortedData = dataWithSeconds.sort((a, b) => b.seconds - a.seconds);

  // Separate the sorted data back into arrays
  const sortedNavigators = sortedData.map(item => item.navigator);
  const processedData = sortedData.map(item => ({
    y: item.seconds,
    original: item.timeStr
  }));

  const yAxisJson = {
    title: {
      text: "Time (hrs)",
    },
    labels: {
      formatter: function () {
        // Convert seconds to hours for y-axis display
        const totalHours = Math.floor(this.value / 3600);
        return totalHours;
      },
    },
  };

  const tooltipJson = {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
      '<td style="padding:0"><b>{point.original}</b></td></tr>',
    footerFormat: '</table>',
    shared: true,
    useHTML: true
  };

  const columnData = [{
    name: axisName,
    data: processedData,
    color: colorCode
  }];

  const optionsCN1 = {
    chart: {
      type: "column",
      backgroundColor: 'transparent',
      height: 260,
      options3d: {
        enabled: true,
        alpha: 0,
        beta: 15,
        depth: 50,
        viewDistance: 0
      },
      marginTop: [34]
    },
    title: {
      text: "   ",
    },
    subtitle: null,
    xAxis: {
      categories: sortedNavigators,
      gridLineWidth: 0
    },
    yAxis: yAxisJson,
    tooltip: tooltipJson,
    plotOptions: {
      column: {
        borderRadius: 5,
        pointPadding: 0.2,
        groupPadding: 0.1,
        stacking: null,
        dataLabels: {
          enabled: true,
          formatter: function() {
            return this.point.original;
          },
          style: {
            textOutline: 'none'
          }
        }
      }
    },
    series: columnData,
    credits: {
      enabled: false
    },
    legend: {
      enabled: true
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          enabled: false
        }
      }
    },
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={optionsCN1}
      />
    </div>
  );
};

export default SortedWhatsAppAnalyticsBar;