import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useParams } from 'react-router-dom';
import axios from "axios";
import { useUserTabs } from '../../components/common/UserTabsContext';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import './MemberServices.css'
import { Modal } from 'react-bootstrap';
import {
    fetchCallStatus,
    fetchUserSpecificCalls, updateCallStatus, cancel_or_reschedule_appointment
} from "../../components/services/Service";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { MultiSelect } from 'react-multi-select-component';
import { useSelector } from 'react-redux';
import app from '../../../../firebase/firebase';


const MemberCalls = (props) => {
    const userId = props.userId ? props.userId : "";
    let { openOutGoingCallWindow, memberName, mobileNo, extension } = props;
    const webex = useSelector(state => state.webex);
    const currentTime = new Date();
    const [minTime, setMinTime] = useState(new Date());
    const [maxTime, setMaxTime] = useState(new Date());


    const formObject = {
        userId: userId,
        callId: '',
        callReasonMain: '',
        callReasonSub: '',
        callStatus: '',
        callStreams: [],
        callBackDateTime: addDaystoDate(currentTime, 29),
        callBackNotes: '',
        internalNotes: '',
        externalNotes: ''
    }
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [statuses, setStatuses] = useState({});
    const [showCallStatusModal, setShowCallStatusModal] = useState(false);
    const [userCalls, setUserCalls] = useState([]);
    const [streams, setStreams] = useState([]);
    const [selectedStreams, setSelectedStreams] = useState([]);
    const [formData, setFormData] = useState(formObject);
    const [singleSelectStream, setSingleSelectStream] = useState(true);
    const [scheduleCallBool, setScheduleCallBool] = useState(true);
    const [disabledButton, setDisabledButton] = useState(false);
    const [callActionModal, setCallActionModal] = useState(false);


    const [appointmentActionForm, setAppointmentActionForm] = useState({
        action: "",
        reasonForCancellation: "",
        streams: [],
        slot: "",
        appointmentId: ""
    });

    const [requestStatus, setRequestStatus] = useState({
        isCallActionLoading: false
    })

    const [appointmentActionFormErrors, setAppointmentActionFormErrors] = useState({
        action: "",
        reasonForCancellation: "",
        streams: "",
        slot: "",
    });


    useEffect(() => {
        const now = new Date();
        const isToday = formData.callBackDateTime instanceof Date && formData.callBackDateTime.toDateString() === now.toDateString();

        let minDate;

        if (isToday) {
            // Set minTime to current time if today
            minDate = new Date();
            minDate.setHours(now.getHours(), now.getMinutes(), 0, 0);
        } else {
            // Set minTime to start of the day for future dates
            minDate = new Date(formData.callBackDateTime);
            minDate.setHours(0, 0, 0, 0);
        }

        if (isNaN(minDate.getTime())) {
            //console.error('Invalid minDate value:', minDate);
            minDate = new Date();
        }
        const endOfDay = new Date();
        endOfDay.setHours(23, 59, 59, 999);

        // Ensure endOfDay is a valid Date object
        if (isNaN(endOfDay.getTime())) {
            //console.error('Invalid endOfDay value:', endOfDay);
        }

        setMinTime(minDate);
        setMaxTime(endOfDay);

    }, [formData.callBackDateTime]);


    useEffect(() => {
        getCallStatus();
    }, [userId]);

    useEffect(() => {
        if (webex.callDisposition === false) {
            getUserSpecificCalls();
        }
    }, [userId, webex.callDisposition]);

    const getUserSpecificCalls = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchUserSpecificCalls(userId, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setUserCalls(response.data.data.calls);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const updateUserCallStatus = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await updateCallStatus(formData, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setUserCalls(response.data.data.calls);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const getCallStatus = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchCallStatus(ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setStatuses(response.data.data.status);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const handleChange = (e) => {
        setError("");
        setMessage("");
        if (Array.isArray(e)) {
            let selectedList = [];
            /*if (formData.callStatus == "Completed") {
                selectedList = e;
            } else {
                selectedList = [e[e.length - 1]];
            }*/
            selectedList = e;
            setSelectedStreams(selectedList);
            // Update the formData for callStreams
            setFormData((prevFormData) => ({
                ...prevFormData,
                callStreams: selectedList
            }));
        } else {
            const { name, value } = e.target;

            if (name == "callStatus") {
                setSelectedStreams([]);
                setStreams(statuses[value]);
                if (value == "Completed") {
                    setSingleSelectStream(false);
                } else {
                    setSingleSelectStream(true);
                }
            }
            setFormData((prevFormData) => {
                if (name === "callReasonMain" && value === "New Joiner") {
                    return {
                        ...prevFormData,
                        [name]: value,
                        callReasonSub: ""
                    };
                } else if (name === "callStatus") {
                    return {
                        ...prevFormData,
                        [name]: value,
                        callStreams: []
                    };
                }

                return {
                    ...prevFormData,
                    [name]: value
                };
            });
        }
    };


    const handleUpdateCallStatus = async (callId, callType) => {
        setDisabledButton(false);
        //setScheduleCallBool(false);
        // First, reset formData to formObject
        setFormData((prevFormData) => ({
            ...formObject
        }));

        // Then, update formData with the callId
        setFormData((prevFormData) => ({
            ...prevFormData,
            callId: callId,
            callReasonMain: callType
        }));


        setShowCallStatusModal(true);
        getCallStatus();
        setTimeout(() => {
            if (callType == "New Joiner") {
                document.getElementById("RadioNewJoiner").click();
            } else {
                document.getElementById("RadioFollowUp").click();
            }
        }, 500)
    };

    function addDaystoDate(date, n = 21) {
        let currDate = new Date(date);
        currDate.setDate(currDate.getDate() + n);
        return currDate;
    }

    const handleScheduleCall = async (e) => {
        if (e.target.checked) {
            setScheduleCallBool(true)
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                callBackDateTime: '',
                callBackNotes: ''
            }));
            setScheduleCallBool(false);
        }
    }

    const validateForm = () => {
        setError("");
        setMessage("");
        if (formData.callId == "" || formData.callReasonMain == "" || (formData.callReasonMain == "Follow up" && formData.callReasonSub == "") || formData.callStatus == "" || formData.callStreams.length <= 0 || (scheduleCallBool === true && formData.callBackDateTime == "")) {
            setError('Please enter all the mandatory fields.');
            return false;
        }
        return true;
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            const request = axios.CancelToken.source();
            let ct = {
                cancelToken: request.token
            }
            setDisabledButton(true);
            let fd = {};
            fd = structuredClone(formData);
            if (fd.callBackDateTime !== "") {
                const formattedDate = moment(fd.callBackDateTime).format('YYYY-MM-DD HH:mm:ss');
                fd['callBackDateTime'] = formattedDate;
            }

            await updateCallStatus(fd, ct)
                .then((response) => {
                    if (response.data.code === 200) {
                        setError('');

                        setMessage(response.data.data.message);
                        setTimeout(() => {
                            setMessage('');
                        }, 1000);
                        setTimeout(() => {
                            setShowCallStatusModal(false);
                        }, 1000);
                        getUserSpecificCalls();
                    } else {
                        setMessage('');
                        setError(response.data.data.message);
                        setDisabledButton(false);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
        }
    }

    function handleAppointmentActionChange(e) {

        if (e.target.name === "action" && e.target.value === "cancel") {
            setStreams(statuses["Cancelled"]);
        }

        setAppointmentActionForm({
            ...appointmentActionForm,
            [e.target.name]: e.target.value
        });

        setAppointmentActionFormErrors({
            ...appointmentActionFormErrors,
            [e.target.name]: ""
        });
    }

    function validateAppointmentActionForm() {
        let errors = {};

        if (appointmentActionForm.action == "") {
            errors["action"] = "Please select an action";
        }

        if (appointmentActionForm.action === "cancel") {
            if (appointmentActionForm.reasonForCancellation == "") {
                errors["reasonForCancellation"] = "Please enter reason for cancellation";
            }

            if (appointmentActionForm.streams?.length <= 0) {
                errors['streams'] = "Please select at least one stream";
            }
        }


        if (appointmentActionForm.action === "re-schedule") {
            if (appointmentActionForm.slot == "") {
                errors["slot"] = "Please enter rescheduled date";
            }
        }

        return errors;
    }


    async function saveAppointmentAction() {
        let errors = validateAppointmentActionForm();

        if (Object.keys(errors).length > 0) {
            setAppointmentActionFormErrors({ ...appointmentActionFormErrors, ...errors });
            return;
        }

        setRequestStatus({ ...requestStatus, isCallActionLoading: true });

        try {
            let response = await cancel_or_reschedule_appointment({ ...appointmentActionForm, slot: formatDateTime(appointmentActionForm.slot), streams: appointmentActionForm.streams, userId });

            if (response.data.code === 200) {
                props.callCallsHistory();
                setAppointmentActionFormErrors({
                    ...appointmentActionFormErrors,
                    action: "",
                    reasonForCancellation: "",
                    streams: "",
                    slot: ""
                });
                setAppointmentActionForm({
                    ...appointmentActionForm,
                    action: "",
                    reasonForCancellation: "",
                    streams: [],
                    slot: "",
                    appointmentId: ""
                });

                getUserSpecificCalls();
                setCallActionModal(false);
            } else {

            }
        } catch (error) {

        } finally {
            setRequestStatus({ ...requestStatus, isCallActionLoading: false });
        }

    }


    function formatDateTime(date) {
        if (!date) return "";
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}`;
    };

    return (
        userCalls.length > 0 && (
            <>
                <div>
                    <Row>
                        {
                            userCalls.map((item, index) => {
                                return <Col md={12} className='mt-3'>
                                    <div style={{ backgroundColor: "#ffedd7", borderRadius: "14px", padding: "10px" }}>

                                        <Row className='align-items-center'>
                                            <Col>
                                                <i class="fa fa-bell mx-2" style={{ color: "#FEB558", fontSize: "20px" }} aria-hidden="true"></i>
                                                Appointment Scheduled on <b>{item.date}</b> at <b>{item.time}</b>
                                            </Col>
                                            <Col md="auto d-flex">
                                                {
                                                    (item.isCallEnabled && webex.webexLine) ?
                                                        <div>
                                                            <button
                                                                className={`btn btn-dark coach-calling-btn mx-1`}
                                                                alt="soft phone call"
                                                                style={{ marginRight: '40px' }}
                                                                disabled={webex.isErrorInWebex || !webex.webexLine}
                                                                onClick={e => {
                                                                    if (webex.isErrorInWebex) return;
                                                                    openOutGoingCallWindow({ userId, memberName, mobileNo, extension, callId: item.callId });
                                                                }}

                                                            >
                                                                <i class="bx bxs-phone"></i>
                                                            </button>
                                                        </div> : null
                                                }



                                                <>
                                                    {
                                                        (item.isCallEnabled) && <Button variant="primary" className="btn btn-primary save-btn" type="button" onClick={() => handleUpdateCallStatus(item.callId, item.callType)} style={{ marginRight: '5px' }}>Update Status</Button>
                                                    }
                                                </>

                                                <Button variant="primary" className="btn btn-primary save-btn" type="button" onClick={() => {
                                                    setCallActionModal(true);
                                                    setAppointmentActionForm({
                                                        ...appointmentActionForm,
                                                        appointmentId: item.callId
                                                    })
                                                }}
                                                >Re-schedule / Cancel</Button>

                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>

                    <Modal id="callStatusModal" show={showCallStatusModal} centered onHide={() => setShowCallStatusModal(false)} size="lg" backdrop="static" keyboard={false} fullscreen={false}>
                        <Modal.Header closeButton>
                            <h1 className='modal-title' style={{ width: "90%" }}>Update Call Status</h1>
                        </Modal.Header>
                        <Modal.Body className='disposition_from'>
                            <Row>
                                <Col sm={12}>
                                    <h2 class="module-card-title mb-2" style={{ fontSize: "16px" }}>Call Type <span class="text-danger">*</span></h2>
                                </Col>
                                <Col md="auto">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" id="RadioNewJoiner" name="callReasonMain" value="New Joiner" onClick={handleChange} />
                                        <label class="form-check-label" >New Joiner</label>
                                    </div>
                                </Col>
                                <Col md="auto">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" id="RadioFollowUp" name="callReasonMain" value="Follow up" onClick={handleChange} />
                                        <label class="form-check-label" >Follow up</label>
                                    </div>
                                </Col>
                            </Row>
                            {
                                formData.callReasonMain === "Follow up" &&
                                <Row className='mt-2'>
                                    <Col sm={12}>
                                        <h2 class="module-card-title mb-2" style={{ fontSize: "16px" }}>Call Sub-Type <span class="text-danger">*</span></h2>
                                    </Col>
                                    <Col md="auto">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="callReasonSub" value="Pathway related" onClick={handleChange} />
                                            <label class="form-check-label" >Pathway related</label>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="callReasonSub" value="General Follow up" onClick={handleChange} />
                                            <label class="form-check-label" >General Follow up</label>
                                        </div>
                                    </Col>
                                </Row>
                            }
                            <Row className='mt-4'>
                                <Col sm={12}>
                                    <h2 class="module-card-title mb-2" style={{ fontSize: "16px" }}>Call Status <span class="text-danger">*</span></h2>
                                </Col>
                                <Col>

                                    {
                                        Object.keys(statuses).map(status => (
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="callStatus" value={status} onClick={handleChange} />
                                                <label class="form-check-label" >{(status == "Membernoshow") ? "Member no-show" : status}</label>

                                            </div>
                                        ))
                                    }
                                </Col>
                            </Row>
                            {
                                formData.callStatus !== "" &&
                                <Row className='mt-4'>
                                    <Col sm={12}>
                                        <h2 class="module-card-title mb-2" style={{ fontSize: "16px" }}>Stream <span class="text-danger">*</span></h2>
                                    </Col>
                                    <Col>
                                        <MultiSelect
                                            options={streams}
                                            value={selectedStreams}
                                            onChange={handleChange}
                                            labelledBy="Select"
                                            hasSelectAll={false}
                                            disableSearch={false}
                                            singleSelect={setSingleSelectStream}
                                            overrideStrings={{
                                                selectAll: 'Select All',
                                                allItemsAreSelected: 'All items are selected',
                                                selectSomeItems: 'Select items',
                                            }}
                                            className="form-select select-input-ui select-input-ui-select"
                                            name="callStreams form-select input-field-ui"
                                            id="callStreams"
                                        />
                                    </Col>


                                </Row>
                            }
                            <Row className='mt-4'>
                                <Col sm={6}>
                                    <h2 class="module-card-title mb-2" style={{ fontSize: "16px" }}>Internal Notes</h2>
                                    <textarea className='form-control control-input-ui' value={formData.internalNotes} name="internalNotes" onChange={handleChange}></textarea>
                                </Col>

                                <Col sm={6}>
                                    <h2 class="module-card-title mb-2" style={{ fontSize: "16px" }}>External Notes</h2>
                                    <textarea className='form-control control-input-ui' value={formData.externalNotes} name="externalNotes" onChange={handleChange}></textarea>
                                </Col>
                            </Row>
                            {/*<Row className='mt-4'>
                                <Col>
                                    <div class="form-check">
                                        <input class="form-check-input form-check-input-ui" type="checkbox" name="scheduleCall" value="Y" onClick={handleScheduleCall} />
                                        <label class="form-check-label mt-1"><b>Schedule Follow up Call</b></label>
                                    </div>
                                </Col>
                            </Row>*/}
                            {
                                scheduleCallBool &&
                                <>
                                    <Row>
                                        <Col sm={12} className='mt-2'>
                                            <h2 class="module-card-title mb-2" style={{ fontSize: "16px" }}>Follow-up Call Date & Time <span class="text-danger">*</span></h2>
                                        </Col>
                                        <Col>
                                            <DatePicker
                                                selected={formData.callBackDateTime}
                                                onChange={updatedDate => setFormData({ ...formData, callBackDateTime: updatedDate })}
                                                showTimeSelect
                                                timeIntervals={15}
                                                minDate={currentTime}
                                                maxDate={addDaystoDate(currentTime, 365)}
                                                minTime={minTime}
                                                maxTime={maxTime}
                                                timeInputLabel="Time:"
                                                dateFormat="dd/MM/yyyy h:mm aa"
                                                placeholderText="Select Date and Time"
                                                className='form-select input-field-ui'
                                                onKeyDown={(e) => e.preventDefault()}
                                                onPaste={(e) => e.preventDefault()}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} className='mt-2'>
                                            <h2 class="module-card-title mb-2" style={{ fontSize: "16px" }}>Follow up Call Notes</h2>
                                            <textarea className='form-control control-input-ui' value={formData.callBackNotes} name="callBackNotes" onChange={handleChange}></textarea>
                                        </Col>

                                    </Row>
                                </>
                            }
                            <Row className='mt-3'>
                                <Col>
                                    <div className={`${error != "" ? "errorDiv" : "hide"}`}>{error}</div>
                                    <div className={`${message != "" ? "messageDiv" : "hide"}`}>{message}</div>

                                    <Button variant="primary" className="btn btn-primary save-btn" type="button" onClick={handleSubmit} disabled={disabledButton}>Update Status</Button>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>


                    <Modal id="callActionModal" show={callActionModal} centered
                        onHide={() => {
                            setCallActionModal(false);
                            setAppointmentActionForm({
                                ...appointmentActionForm,
                                action: "",
                                reasonForCancellation: "",
                                streams: [],
                                slot: "",
                                appointmentId: ""
                            });

                            setAppointmentActionFormErrors({
                                action: "",
                                reasonForCancellation: "",
                                streams: "",
                                slot: ""
                            });
                        }} size="lg" backdrop="static" keyboard={false} fullscreen={false}>
                        <Modal.Header closeButton>
                            <h1 className='modal-title' style={{ width: "100%" }}>Re-schedule / Cancel Appointment</h1>
                        </Modal.Header>
                        <Modal.Body className='disposition_from'>
                            <Row>
                                <Col sm={12}>
                                    <h2 class="module-card-title mb-2" style={{ fontSize: "16px" }}>Action <span class="text-danger">*</span></h2>
                                </Col>
                                <Col md="auto">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="action" value="re-schedule" onClick={handleAppointmentActionChange} />
                                        <label class="form-check-label" >Re-Schedule Appointment</label>
                                    </div>
                                </Col>
                                <Col md="auto">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="action" value="cancel" onClick={handleAppointmentActionChange} />
                                        <label class="form-check-label" >Cancel Appointment</label>
                                    </div>
                                </Col>

                                {appointmentActionFormErrors.action && <p style={{ color: "red" }}>{appointmentActionFormErrors.action}</p>}
                            </Row>

                            {
                                appointmentActionForm.action === "cancel" &&
                                <>

                                    <Row className='mt-4'>
                                        <Col sm={12}>
                                            <h2 class="module-card-title mb-2" style={{ fontSize: "16px" }}>Stream <span class="text-danger">*</span></h2>
                                        </Col>
                                        <Col>
                                            <MultiSelect
                                                options={streams}
                                                value={appointmentActionForm.streams}
                                                onChange={e => {
                                                    setAppointmentActionForm({
                                                        ...appointmentActionForm,
                                                        streams: e
                                                    });

                                                    setAppointmentActionFormErrors({
                                                        ...appointmentActionFormErrors,
                                                        streams: ""
                                                    });
                                                }}
                                                labelledBy="Select"
                                                hasSelectAll={false}
                                                disableSearch={false}
                                                singleSelect={setSingleSelectStream}
                                                overrideStrings={{
                                                    selectAll: 'Select All',
                                                    allItemsAreSelected: 'All Streams are selected',
                                                    selectSomeItems: 'Select Streams',
                                                }}
                                                className="form-select select-input-ui select-input-ui-select"
                                                name="callStreams form-select input-field-ui"
                                                id="callStreams"
                                            />
                                        </Col>
                                        {appointmentActionFormErrors.streams && <p style={{ color: "red", fontWeight: "300" }}>{appointmentActionFormErrors.streams}</p>}
                                    </Row>
                                    <Row className='mt-4'>
                                        <Col sm={12}>
                                            <h2 class="module-card-title mb-2" style={{ fontSize: "16px", fontWeight: "300" }}>Reason for Cancellation <span style={{ color: "red" }}>*</span></h2>
                                            <textarea className='form-control control-input-ui' value={appointmentActionForm.reasonForCancellation} name="reasonForCancellation" onChange={handleAppointmentActionChange}></textarea>
                                        </Col>
                                        {appointmentActionFormErrors.reasonForCancellation && <p style={{ color: "red" }}>{appointmentActionFormErrors.reasonForCancellation}</p>}

                                    </Row>

                                </>
                            }


                            {
                                appointmentActionForm.action === "re-schedule" &&
                                <>
                                    <Row>
                                        <Col sm={12} className='mt-2'>
                                            <h2 class="module-card-title mb-2" style={{ fontSize: "16px" }}>Follow-up Call Date & Time <span class="text-danger">*</span></h2>
                                        </Col>
                                        <Col>
                                            <DatePicker
                                                selected={appointmentActionForm.slot}
                                                onChange={updatedDate => {
                                                    setAppointmentActionForm({ ...appointmentActionForm, slot: updatedDate });
                                                    setAppointmentActionFormErrors({
                                                        ...appointmentActionFormErrors,
                                                        slot: ""
                                                    });
                                                }}
                                                showTimeSelect
                                                timeIntervals={15}
                                                minDate={currentTime}
                                                maxDate={addDaystoDate(currentTime, 365)}
                                                minTime={appointmentActionForm.slot && appointmentActionForm.slot > new Date() ? new Date(new Date().setHours(0, 0, 0, 0)) : new Date()}
                                                maxTime={new Date().setHours(23, 59, 59)}

                                                timeInputLabel="Time:"
                                                dateFormat="dd/MM/yyyy h:mm aa"
                                                placeholderText="Select Date and Time"
                                                className='form-select input-field-ui'
                                                onKeyDown={(e) => e.preventDefault()}
                                                onPaste={(e) => e.preventDefault()}
                                            />
                                            {appointmentActionFormErrors.slot && <p style={{ color: "red" }}>{appointmentActionFormErrors.slot}</p>}
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                        <Col sm={12} className='mt-2'>
                                            <h2 class="module-card-title mb-2" style={{ fontSize: "16px" }}>Follow up Call Notes</h2>
                                            <textarea className='form-control control-input-ui' value={formData.callBackNotes} name="callBackNotes" onChange={handleChange}></textarea>
                                        </Col>
                                    </Row> */}
                                </>
                            }
                            <Row className='mt-3'>
                                <Col>
                                    <div className={`${error != "" ? "errorDiv" : "hide"}`}>{error}</div>
                                    <div className={`${message != "" ? "messageDiv" : "hide"}`}>{message}</div>

                                    <Button variant="primary" className="btn btn-primary save-btn" type="button" onClick={saveAppointmentAction}
                                        disabled={requestStatus.isCallActionLoading ? "disabled" : ""}
                                    >
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal >
                </div>
            </>
        )
    )
}

export default MemberCalls