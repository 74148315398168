import React, { useEffect, useState } from "react";
import { fetch_program_data, fetch_role_data, medengage_admin_user_update_data, medengage_fetch_admin_user_data, } from "../../../services/medengage/Service";
import { editValidateForm } from "../../utils/validationUtils";
import { useNavigate, useParams } from "react-router-dom"; // assuming you're using react-router for routing
import { LoaderLoader } from "../../utils/CommonLibrary";

function ContentEditUser() {    
    const { id:userId } = useParams(); // get user ID from URL parameters 
    const [roleData, setRoleData] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const [programData, setprogramData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showProgram, setShowProgram] = useState(false);

    const [formData, setFormData] = useState({
        userName: '',
        firstName: '',
        lastName: '',
        emailId: '',
        programId: [],
        roleId: '',
        imgUrl: '',
        adminStatus:'',
        programIdArr:[],
        adminShow: '',
        whatsAppEnable:'',
    });
    const [errors, setErrors] = useState({
        userName: '',
        firstName: '',
        lastName: '',
        emailId: '',
        programId: [],
        roleId: '',
        imgUrl: '',
        adminStatus:'',
        programIdArr:[],
        whatsAppEnable:'',
      });

    useEffect(() => {
        fetchRoleData();
        fetchUserData(userId);
        fetchProgramData();
    }, [userId]);

    const fetchRoleData = async () => {
        try {
            const response = await fetch_role_data();
            setRoleData(response.data.data);
        } catch (error) {
            console.error(error);
        }
    };

    //fetch program data
const fetchProgramData = async () => {
    try {
        const resp = await fetch_program_data();
        if(resp.data.code == 200){
            setprogramData(resp.data.data);
        }     
    } catch (error) {
        console.error(error);
    }
}

    const fetchUserData = async (userId) => {
        try {
            setIsLoading(true) //show loader
            let response = await medengage_fetch_admin_user_data(userId);
            if(response.data.code === 200){
                setFormData(response.data.data);
            }
            setIsLoading(false) //hide loader
        } catch (error) {
            console.error(error);
        }
    };


    const handleChange = async (e) => {
        const { name, value, files } = e.target;
    
    
        // if (name === "programId" && e.target.checked) {
        //   setFormData({
        //     ...formData,
        //     programIdArr: [...formData.programIdArr, parseInt(value)],
        //   });
        // } else 
        
        if (name == "programId" && !e.target.checked) {
          setFormData({
            ...formData,
            programIdArr: formData.programIdArr.filter(
              (item) => item !== parseInt(value)
            ),
          });
        } else {
          setFormData({
            ...formData,
            [name]: name === "imgUrl" ? files[0] : value,
          });
        }
    
        setErrors({
          ...errors,
          [name]: "",
        });
      };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = editValidateForm(formData);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            try {
                setIsLoading(true) //show loader
                const response = await medengage_admin_user_update_data(userId, formData);
                if (response.data.code === 200) {
                    window.location.href = "/member-management-medengage";
                    setSuccessMessage(response.data.data.message);
                    setErrorMessage('');
                } else {
                    setErrorMessage(response.data.data.message);
                }
                setIsLoading(false) //show loader
            } catch (error) {
                console.error(error);
                setErrorMessage("Exception in submit action");
            }
        }
    };

    const handleBackButton = () => {
        navigate('/member-management-medengage');
    }
    const navigate = useNavigate();

    return (
        <>
            <div className="container-fluid px-0">
            <div className="row align-items-center pb-3 sticky-top-bar">
                    <div className="col-md" style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                    <button className="btn btn-primary back-btn mb-0 me-3" onClick={handleBackButton}><i class="bi bi-chevron-left me-1"></i> Back</button>
                    <h1 className="main-title" style={{ margin: '0' }}>Edit User</h1>
                    </div>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className='comntent-UserManagement p-3 mb-3'>
                        <div className="row">
                            <div className="col-md-7 px-4">
                                {successMessage && <div className="text-success">{successMessage}</div>}
                                {errorMessage && <div className="text-danger">{errorMessage}</div>}
                                <LoaderLoader isLoading={isLoading} />
                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="emailId" className="form-label">Email Id</label>
                                        <span style={{color:'red'}}>&nbsp;*</span>
                                        <input type="text" className="form-control input-field-ui" id="emailId" name="emailId" value={formData.emailId} onChange={handleChange} placeholder="Enter Email Id"  disabled={isDisabled} />
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="firstName" className="form-label">First Name</label>
                                        <span style={{color:'red'}}>&nbsp;*</span>
                                        <input type="text" className="form-control input-field-ui" id="firstName" name="firstName" value={formData.firstName} onChange={handleChange} placeholder="Enter Name" disabled={isDisabled} />
                                        {errors.firstName && <div className="text-danger">{errors.firstName}</div>}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="lastName" className="form-label">Last Name</label>
                                        <span style={{color:'red'}}>&nbsp;*</span>
                                        <input type="text" className="form-control input-field-ui" id="lastName" name="lastName" value={formData.lastName} onChange={handleChange} placeholder="Enter Last Name" disabled={isDisabled} />
                                        {errors.lastName && <div className="text-danger">{errors.lastName}</div>}
                                    </div>

                                    <div className="col-md-6 mb-3">
                                    
                                    <input
    className="form-check-input form-check-input-ui"
    type="checkbox"
    name="whatsAppEnable"  // Changed to match the state property name
    checked={formData.whatsAppEnable === 'Y'}
    onChange={(e) => {
        setFormData(prev => ({
            ...prev,
            whatsAppEnable: e.target.checked ? 'Y' : 'N'
        }));
    }}
/> <label htmlFor="lastName" className="form-label"> Review and Manage Templates</label> <span style={{color:'red'}}>&nbsp;*</span>
                                   
                                    </div>
                                   
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="selectRole" className="form-label">Select Role</label>
                                        <span style={{color:'red'}}>&nbsp;*</span>
                                        <select className="form-select input-field-ui" id="roleId" name="roleId" value={formData.roleId} onChange={handleChange} aria-label="Default select example">
                                            <option value="">Select Role</option>
                                            {roleData.map((option,index) => (
                                               <option key={index} value={option.roleId}>{option.roleName}</option>
                                            ))}
                                        </select>
                                        {errors.roleId && <div className="text-danger">{errors.roleId}</div>}
                                    </div>

                                    {showProgram && <div className="col-md-12 mb-3">
                                        <label htmlFor="programId" className="form-label">Select Program(s)</label>
                                        <span style={{color:'red'}}>&nbsp;*</span>
                                        <div className={`form input-field-ui ${errors['programId'] ? 'is-invalid' : ''}`} >
                                            {programData.map((item, index) => (
                                                <div key={`program-${item["programId"]}`}>
                                                    <label className="me-4">
                                                        <input
                                                        type="checkbox"
                                                        className="me-2"
                                                        name="programId"
                                                        value={item["programId"]}
                                                        id={`programId-${item["programId"]}`}
                                                        onChange={handleChange}
                                                        checked={formData["programIdArr"].includes(
                                                            item["programId"]
                                                        )}
                                                        />
                                                        {item["programName"]}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                        {errors.programId && <div className="text-danger">{errors.programId}</div>}
                                    </div> }
                                   

                                    {formData.adminShow && <div className="col-md-12 mb-3">
                                        <label htmlFor="selectRole" className="form-label">Status</label>
                                        <span style={{color:'red'}}>&nbsp;*</span>
                                        <select className="form-select input-field-ui" id="adminStatus" name="adminStatus" value={formData.adminStatus} onChange={handleChange} aria-label="Default select example">
                                            <option value="">Select Status</option>
                                            <option value="active">Active</option>
                                            <option value="inactive">Inactive</option>
                                        </select>
                                        {errors.adminStatus && <div className="text-danger">{errors.adminStatus}</div>}
                                    </div> }
                                    <div className="col-md-12 mb-3">
                                        <button type="submit" className="btn btn-primary program-save-btn me-2">Save</button>
                                        <button type="button" className="btn btn-secondary clear-btn" onClick={() => window.history.back()}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default ContentEditUser;
